import {FC} from "react";
import {ReactComponent as EnvelopeIcon} from "./resources/Envelope.svg";
import {Button, ButtonType} from "../../button/Button";
import {useNavigate} from "react-router-dom";
import {Pages} from "../../../../routing/Pages";

export const ContactUsButton: FC = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(Pages.ABOUT);
    };
    return (
        <Button type={ButtonType.SECONDARY} icon={<EnvelopeIcon/>} onClick={handleClick}>Напишіть нам</Button>
    );
}