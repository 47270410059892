import {FC} from "react";
import "./styles/copyright.scss";

export const Copyright: FC = () => {
  return (
      <div className="app-copyright">
          © {new Date().getFullYear()} BARON.UA, All Rights Reserved.
      </div>
  )
};
