import Command from "../../fetch/Command";
import {api} from "../../fetch/api";
import {CatalogDecorator} from "./CatalogDecorator";

export type Catalog = Record<string, any>;

export interface CatalogResult {
    catalog: Catalog,
    id: string
}


export default class GetCatalogCommand implements Command<CatalogDecorator> {
    static catalog: CatalogDecorator | null = null;
    static promise: Promise<CatalogDecorator> | null = null;

    async execute(): Promise<CatalogDecorator> {
        if (!GetCatalogCommand.catalog && !GetCatalogCommand.promise) {
            GetCatalogCommand.promise = api<CatalogResult>("/api/catalog/latest").then((result: CatalogResult) => {
                GetCatalogCommand.catalog = new CatalogDecorator(result);
                return Promise.resolve(GetCatalogCommand.catalog);
            }).finally(() => {
                GetCatalogCommand.promise = null;
            });
        }
        if (GetCatalogCommand.promise) {
            return GetCatalogCommand.promise;
        }

        return Promise.resolve(GetCatalogCommand.catalog as CatalogDecorator);
    }
}