import {FC, ReactNode} from "react";
import {Button, ButtonType} from "../button/Button";

export interface NotificationProps {
    duration?: number;
    onRetry?: () => void;
    text?: ReactNode;
    message?: string;
}

const Notification: FC<NotificationProps> = (props: NotificationProps) => {
    return (
        <div className="notification">
            <p>{props.text}</p>
            {props.onRetry && (<Button type={ButtonType.OUTLINE} onClick={props.onRetry}>Повторити</Button>)}
        </div>
    );
}

export default Notification;