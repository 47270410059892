import {ItemType} from "antd/lib/menu/hooks/useItems";
import {CatalogDecorator} from "../../../commands/catalog/CatalogDecorator";

export function menu(catalog: CatalogDecorator | null): ItemType[] {
    if (!catalog) {
        return [];
    }
    return catalog.menu();
}

export enum StaticMenuItemKeys {
    LEGAL_ASPECTS = "legalAspects",
    ABOUT = "about"
}

export const STATIC_MENU_ITEMS: ItemType[] = [
    {
        label: "Юридичні Аспекти",
        key: StaticMenuItemKeys.LEGAL_ASPECTS,
    },
    {
        label: "Про нас",
        key: StaticMenuItemKeys.ABOUT,
    },
];

