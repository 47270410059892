import {FC} from "react";
import {Badge} from "antd";
import AdvertisementDecorator from "../../../types/decorators/AdvertisementDecorator";
import {generatePath, Link} from "react-router-dom";
import {Pages} from "../../../routing/Pages";
import {useCurrentUser} from "../../commands/user/useCurrentUser";
import {AdToFavouritesButton} from "../adToFavouritesButton/AdToFavouritesButton";
import cx from "classnames";
import {Image} from "../images/image/Image";
import "./style/productTile.scss";

interface Props {
    advertisement: AdvertisementDecorator;
    className?: string;
}

export const ProductTile: FC<Props> = (props: Props) => {
    const {advertisement, className} = props;
    const [currentUserInfo] = useCurrentUser();
    const isFavorite = currentUserInfo ? currentUserInfo.hasFavorite(advertisement.id) : false;
    const cl: string = cx("product-tile", {className: className, "product-tile--promoted": advertisement.isPromoted});
    const getContent = () => (
        <>
            <Image src={advertisement.thumbnails[0]} alt="Product photo"/>
            <h4 className="product-tile__title">{advertisement.title}</h4>
            {advertisement.caliber && (
                <p className="product-tile__details">{advertisement.caliber}</p>
            )}
            <p className="product-tile__price">{advertisement.price}</p>
        </>
    );

    return (
        <Link to={generatePath(Pages.AD_PUBLIC_DETAILS, {adId: advertisement.id})}
              className={cl} key={advertisement.id}>
            {advertisement.isPromoted
                ? (
                    <Badge.Ribbon text="ТОП" color="#FF5722">
                        {getContent()}
                    </Badge.Ribbon>
                )
                : getContent()}
            <AdToFavouritesButton isFavorite={isFavorite} advertisement={advertisement}/>
        </Link>
    );
};