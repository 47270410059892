import React, {lazy} from "react";
import ReactDOM, {Root} from "react-dom/client";
import ErrorBoundary from "./common/components/errorBoundary/ErrorBoundary";
import {BrowserRouter} from "react-router-dom";
import {ConfigProvider} from "antd";
import ukUa from 'antd/es/locale/uk_UA';
import "./index.scss";
import App from "./App";
import {LazyPage} from "./content/lazyPage/LazyPage";

const ErrorScreen = lazy(() => import("./common/components/errorScreen/ErrorScreen"));

const gerRoot = function () {
    let rootInstance: Root | null = null;

    return function () {
        if (!rootInstance) {
            rootInstance = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
        }
        return rootInstance;
    };
}();

export function render(Component: React.ComponentType) {
    gerRoot().render(
        <ConfigProvider locale={ukUa}>
            <ErrorBoundary>
                <BrowserRouter>
                    <Component/>
                </BrowserRouter>
            </ErrorBoundary>
        </ConfigProvider>
    );
}

window.onerror = () => {
    const error = <LazyPage component={ErrorScreen}/>

    gerRoot().render(error);
};

render(App);