import {FC} from "react";
import {Logo} from "./logo/Logo";
import {CreateNewAdButton} from "./createNewAd/CreateNewAdButton";
import {UserProfileMenu} from "./userProfileMenu/UserProfileMenu";
import {Messages} from "./messages/Messages";
import {SavedAds} from "./savedAds/SavedAds";
import {HeaderMenu} from "./headerMenu/HeaderMenu";
import {useLocation} from "react-router-dom";
import {HeaderSearchForm} from "./headerSearch/HeaderSearchForm";
import {useCurrentUser} from "../../commands/user/useCurrentUser";
import {MobileMenu} from "./mobileMenu/MobileMenu";
import {
    isCreateNewAdButtonVisible,
    isMessageButtonVisible,
    isProfileButtonVisible,
    isSearchVisible
} from "../../utils/LocationUtils";
import {useIsMobile} from "../../hooks/useIsMobile";
import {Affix} from "antd";
import "./styles/header.scss";

export const Header: FC = () => {
    const location = useLocation();
    const [currentUser] = useCurrentUser();
    const isMobile = useIsMobile();
    return (
        <header className="app-header">
            <Affix>
                <div className="app-header__top">
                    <div className="app-header__container">
                        {!isMobile && (
                            <>
                                <div className="app-header__top-left">
                                    <Logo/>
                                </div>
                                <div className="app-header__top-right">
                                    <ul className="app-header__links">
                                        {isMessageButtonVisible(location.pathname, currentUser) && (
                                            <>
                                                <li className="app-header__link">
                                                    <SavedAds/>
                                                </li>
                                                <li className="app-header__link">
                                                    <Messages/>
                                                </li>
                                            </>
                                        )}
                                        {isProfileButtonVisible(location.pathname) && (
                                            <li className="app-header__link">
                                                <UserProfileMenu/>
                                            </li>
                                        )}
                                    </ul>
                                    {isCreateNewAdButtonVisible(location.pathname, currentUser) && (
                                        <CreateNewAdButton/>
                                    )}
                                </div>
                            </>
                        )}
                        {isMobile && (
                            <>
                                <MobileMenu/>
                                <div className="app-header__mobile-logo">
                                    <Logo/>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Affix>
            {(isSearchVisible(location.pathname, currentUser) && !isMobile) && (
                <>
                    <div className="app-header__search">
                        <div className="app-header__container">
                            <HeaderSearchForm/>
                        </div>
                    </div>
                    <div className="app-header__menu">
                        <div className="app-header__container">
                            <HeaderMenu/>
                        </div>
                    </div>
                </>
            )}
        </header>
    );
};
