import {FC, useEffect, useState} from "react";
import {Collapse, Form, Input, Radio, Select, Space} from "antd";
import {getCategories} from "../../newAdPage/CategoriesMenuBuilder";
import {useCatalog} from "../../../common/commands/catalog/useCatalog";
import {PriceRange} from "../../../common/components/priceRange/PriceRange";
import {SingleValueType} from "rc-cascader/lib/Cascader";
import {isEmpty, omit} from "lodash-es";
import {getPickList, StaticDataItem} from "../../../common/staticData/StaticData";
import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";
import {Pages} from "../../../routing/Pages";
import {cleanedObject, searchParamsToObject} from "../../../common/utils/ObjectUtils";
import {ReactComponent as SearchIcon} from "../../../common/components/header/headerSearch/resources/SearchIcon.svg";
import {Button, ButtonType} from "../../../common/components/button/Button";
import {CategoriesSelect} from "../../../common/components/categoriesSelect/CategoriesSelect";
import {ReactComponent as Close} from "./resources/Close.svg";
import {useScreenWidth} from "../../../common/hooks/useScreenWidth";
import {isMobile} from "../../../common/components/screen/Screen";
import {getCharacteristicOptions} from "../../../common/utils/CatalogUtils";
import "./styles/filtersPanel.scss";

const {Panel} = Collapse;

interface Props {
    onCLoseClick?: () => void;
    onSubmit?: () => void;
}


export const FilterPanel: FC<Props> = (props: Props) => {
    const {onCLoseClick, onSubmit} = props;
    const [form] = Form.useForm();
    const [searchParams] = useSearchParams();
    const [catalog] = useCatalog();
    const navigate = useNavigate();
    const [categories, setCategories] = useState<SingleValueType | undefined>([]);
    const [characteristics, setCharacteristics] = useState<Record<string, []> | undefined>(undefined);
    const width = useScreenWidth();
    const handleCategoryChange = (value: SingleValueType | undefined) => {
        setCategories(value);
        if (catalog && value) {
            setCharacteristics(catalog.getCharacteristics(value as string[]));
        }
    };

    const handleSubmit = (values: Record<string, any>) => {
        if (categories && !isEmpty(categories)) {
            values["category"] = [categories[values["category"].length - 1]];
        }
        navigate({
            pathname: Pages.SEARCH,
            search: `?${createSearchParams({...cleanedObject(values), type: values["type"] || searchParams.get("type") as string})}`
        });
        window.scrollTo(0, 0)
    };

    const submitForm = () => {
        form.submit();
        onSubmit?.();
    };

    const resetForm = () => {
        form.resetFields();
    };

    const handleCloseClick = () => {
        onCLoseClick?.();
    };

    useEffect(() => {
        const searchParamsObject = searchParamsToObject(searchParams);
        const category: string[] | undefined = searchParamsObject["category"];
        const categories: string[] | undefined = catalog?.pathToLeaf((category || [])[0])?.split(".");
        searchParamsObject["category"] = categories;
        handleCategoryChange(categories);
        form.resetFields();
        form.setFieldsValue(searchParamsObject);

    }, [searchParams, catalog]);

    return (
        <div className="filtersPanel">
            <Form layout="vertical" form={form} onFinish={handleSubmit} initialValues={{type: "Всі"}}>
                <div className="filtersPanel__items-wrapper">
                    <Collapse defaultActiveKey={['Пошукова фраза']}>
                        <Panel header="Пошукова фраза" key="Пошукова фраза">
                            <Form.Item name="searchPhrase">
                                <Input placeholder="Пошукова фраза"/>
                            </Form.Item>
                        </Panel>
                    </Collapse>
                    <Collapse defaultActiveKey={['КатегоріЇ']}>
                        <Panel header="КатегоріЇ" key="КатегоріЇ">
                            {isMobile(width) && (
                                <Form.Item name="type">
                                    <Radio.Group>
                                        <Space direction="vertical">
                                            {
                                                getPickList("adTypes")
                                                    .map((item: StaticDataItem) =>
                                                        (
                                                            <Radio
                                                                value={item.value}
                                                                key={item.value}>
                                                                {item.label}
                                                            </Radio>
                                                        )
                                                    )
                                            }
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            )}
                            <Form.Item name="category">
                                <CategoriesSelect options={getCategories(catalog?.src ?? {})}
                                                  onChange={handleCategoryChange}/>
                            </Form.Item>
                        </Panel>
                    </Collapse>
                    <Collapse defaultActiveKey={['Ціна']}>
                        <Panel header="Ціна" key="Ціна">
                            <PriceRange fromLabel="Мінімальна" toLabel="Максимальна"/>
                        </Panel>
                    </Collapse>
                    {!isEmpty(categories) && !isEmpty(characteristics) && (
                        <Collapse defaultActiveKey={['Характеристики']}>
                            <Panel header="Характеристики" key="Характеристики"
                                   className="filtersPanel__characteristics">
                                {
                                    Object.keys(omit(characteristics, "id")).map((key: string) =>
                                        (
                                            <Form.Item
                                                key={key}
                                                name={key}
                                                label={key}
                                            >
                                                <Select
                                                    mode="multiple"
                                                    options={getCharacteristicOptions(characteristics,key)}/>
                                            </Form.Item>
                                        )
                                    )
                                }
                            </Panel>
                        </Collapse>
                    )}
                    <Collapse defaultActiveKey={['Область']}>
                        <Panel key="Область" header="Область">
                            <Form.Item
                                name="location"
                            >
                                <Select options={getPickList("region")} placeholder="Область"
                                        dropdownMatchSelectWidth={false} allowClear={true}
                                        mode="multiple"
                                />
                            </Form.Item>
                        </Panel>
                    </Collapse>
                </div>
                {!isMobile(width)
                    ? (
                        <div className="filtersPanel__footer">
                            <Button onClick={submitForm} className="filtersPanel__submit" type={ButtonType.PRIMARY}
                                    icon={<SearchIcon/>}>Пошук</Button>
                        </div>
                    )
                    : (
                        <div className="filtersPanel__footer--mobile">
                            <button type="button" className="filtersPanel__close" onClick={handleCloseClick}>
                                <Close/>
                            </button>
                            <Button type={ButtonType.SECONDARY} onClick={resetForm}>Скинуте все</Button>
                            <Button type={ButtonType.PRIMARY} onClick={submitForm} >Пошук</Button>
                        </div>
                    )
                }
            </Form>
        </div>
    );
}