import React from "react";
import {AppRoutes} from "./routing/Routes";
import {Header} from "./common/components/header/Header";
import {Footer} from "./common/components/footer/Footer";
import {Spin} from "antd";
import {useCurrentUser} from "./common/commands/user/useCurrentUser";
import {ScrollToTop} from "./common/components/scrollToTop/ScrollToTop";
import {CookiesConfirmation} from "./content/cookies/CookiesConfirmation";
import {isMobile} from "./common/components/screen/Screen";
import {MobileUserLinks} from "./common/components/mobileUserLinks/MobileUserLinks";
import {useScreenWidth} from "./common/hooks/useScreenWidth";
import {useCatalog} from "./common/commands/catalog/useCatalog";
import {Helmet} from "react-helmet";
import {getAllKeys} from "./common/utils/ObjectUtils";
import "./styles/app.scss";

function App() {
    const [, , isLoading] = useCurrentUser();
    const width: number = useScreenWidth();
    const [catalog] = useCatalog();

    return (
        <div className="app">
            <Helmet>
                <title>
                    Baron.ua
                </title>
                <meta name="description" content="Барон є торговельною платформою та маркетплейсом, де сотні тисяч українців можуть купувати і продавати все, що їм може знадобитися в ці непрості для країни часи.

На нашій платформі ви маєте можливість обирати з тисяч пропозицій як від приватних продавців, так і від найбільших мереж, що спеціалізуються на продажу мілітарних товарів.

Окрема місія нашої платформи – підтримка вітчизняних виробників якісних військових товарів, амуніції та продукції сектору мілтех.

Сьогодні Барон вже є найбільш зручним місцем для продажу та придбання мілітарних товарів в Україні, і ми віримо, що незабаром станемо найкращим вибором для свідомих громадян по всій Європі.">
                </meta>
                {catalog && (
                    <meta name="keywords"
                          content={`baron, baron.ua, барон, оружейный барон, барон юа, ${getAllKeys(catalog.src).join(", ")}`}/>
                )}
            </Helmet>
            <CookiesConfirmation/>
            <ScrollToTop/>
            <Header/>
            <div className="app-content">
                {isLoading && (
                    <div className="app-spin-container">
                        <Spin size="large"/>
                    </div>
                )}
                {!isLoading && (
                    <AppRoutes/>
                )}

            </div>
            {isMobile(width) && <MobileUserLinks/>}
            {!isMobile(width) && (<Footer/>)}
        </div>
    );
}

export default App;
