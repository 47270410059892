import {FC} from "react";
import {IconButton} from "../../../iconButton/IconButton";
import {signOut} from "firebase/auth";
import {auth} from "../../../../../firebase/config";
import {ReactComponent as LogoutIcon} from "./resources/LogOut.svg";
import {useCurrentUser} from "../../../../commands/user/useCurrentUser";
import {useNavigate} from "react-router-dom";
import {Button, ButtonType} from "../../../button/Button";
import cn from "classnames";
import "./logoutButton.scss";

interface Props {
    type?: LogoutButtonTypes;
    className?: string;
}

export enum LogoutButtonTypes {
    BUTTON = "BUTTON",
    ICON = "ICON"
}

export const LogoutButton: FC<Props> = (props: Props) => {
    const {type= LogoutButtonTypes.ICON, className} = props;
    const [, setCurrentUserInfo] = useCurrentUser();
    const navigate = useNavigate();
    const handleClick = async () => {
        try {
            await signOut(auth);
            setCurrentUserInfo(null);
            navigate("/");

        } catch (e) {
            console.log(e);
        }
    };
    if(type === LogoutButtonTypes.ICON) {
        return (
            <IconButton className={cn(className, "logoutButton-iconButton")} onClick={handleClick}>
                <LogoutIcon/>
                Вихід
            </IconButton>
        );
    }else {
        return (
            <Button type={ButtonType.LINK_OUTLINE} className={cn(className, "logoutButton-button")} icon={<LogoutIcon/>}
                    onClick={handleClick}>Вихід</Button>
        );
    }

};