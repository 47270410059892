import "./style/image.scss";
import cn from "classnames";

interface Props {
    src: string;
    alt?: string
    className?: string;
    width?: string;
    height?: string;
    onClick?: () => void;
    showBorder?: boolean;
}

export const Image = (props: Props) => {
    const {src, alt, className, width, height, onClick, showBorder} = props;
    return (
        <div className={cn("image", className, {"image_border": showBorder})} style={{width, height}} onClick={onClick}>
            <img src={src} alt={alt} className="image__content"/>
        </div>
    );
}