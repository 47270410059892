import FetchCommand from "../../../../fetch/FetchCommand";
import {api} from "../../../../fetch/api";

export type UnreadConversationsCount = {
    mine: number;
    other: number;
    total: number;

}

export default class GetUnreadConversationsCount extends FetchCommand<UnreadConversationsCount, undefined> {
    async execute() {
        const result: Record<string, number> = await api<Record<string, number>>("/api/conversations/unread/count");
        const {mine = 0, other = 0} = result
        return {mine, other, total: mine + other}
    }
}