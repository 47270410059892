import {Button, ButtonType} from "../../common/components/button/Button";
import {Link} from "react-router-dom";
import {Pages} from "../../routing/Pages";
import {useEffect, useState} from "react";
import "./styles/cookiesConfirmation.scss";

const COOKIES_ACCEPTANCE: string = "COOKIES_ACCEPTANCE"
export const CookiesConfirmation = () => {
    const [showNotification, setShowNotification] = useState(false);

    const handleOk = () => {
        window.localStorage.setItem(COOKIES_ACCEPTANCE, new Date().toDateString());
        setShowNotification(false);
    };

    useEffect(() => {
        const cookiesAcceptance: string | null = window.localStorage.getItem(COOKIES_ACCEPTANCE);
        setShowNotification(cookiesAcceptance === null);
    }, []);

    if (!showNotification) {
        return null;
    }
    return (
        <div className="cookiesConfirmation">
            <span className="cookiesConfirmation__title">Використання файлів Cookie</span>
            <span>Цей сайт використовує <Link to={Pages.COOKIES}>файли cookie</Link> для забезпечення найкращого досвіду. Підтверджуючи, ви погоджуєтесь із <Link
                to={Pages.PRIVACY}>Політикою конфіденційності</Link></span>

            <Button type={ButtonType.SECONDARY} onClick={handleOk}>Підтверджую</Button>
        </div>
    );
}