import {Catalog, CatalogResult} from "./GetCatalogCommand";
import {get, isEmpty, isObject, memoize, omit} from "lodash-es";
import {ItemType} from "antd/lib/menu/hooks/useItems";

export class CatalogDecorator {
    src: Catalog;
    id: string;

    constructor(catalogResult: CatalogResult) {
        this.src = catalogResult.catalog;
        this.id = catalogResult.id;
    }

    pathToLeaf = memoize((leaf: string): string | undefined => {
        //TODO: Debug why last category is appended twice
        const path: string[] | undefined = [...new Set(this.findPath(this.src, leaf))];

        if (!isEmpty(path)) {
            return path.join(".");
        }
        return undefined;
    });

    protected findPath = (catalog: Catalog | null, item?: string, key?: string): string[] | undefined => {
        if (!catalog || !item) return undefined;
        const result: string[] = [];
        if (catalog.hasOwnProperty(item) || catalog.id === item) {
            result.push(catalog.id === item ? key ?? "" : item);
            return result;
        } else {
            for (const key in catalog) {
                if (!Array.isArray(catalog[key]) && isObject(catalog[key])) {
                    result.push(key);
                    const leaf = this.findPath(catalog[key], item, catalog[key].id ? key : undefined);
                    if (leaf && !isEmpty(leaf)) {
                        result.push(...leaf);
                        return result;
                    } else {
                        result.pop();
                    }
                }
            }
        }
        return result;
    };

    getAllCategories = memoize((leaf: string): string[] => {
        const pathToLeaf: string | undefined = this.pathToLeaf(leaf)
        if (!pathToLeaf) {
            return [];
        }
        return this.getCategories(get(this.src, pathToLeaf));
    });

    protected getCategories(catalog: Catalog): string[] {
        const result: string[] = [];
        for (const key in catalog) {
            if (Array.isArray(catalog[key][Object.keys(catalog[key])[0]])) {
                result.push(key);
            }
            result.push(...this.getCategories(catalog[key]));
        }
        return result;
    }

    topLevelCategories(): string[] {
        return Object.keys(this.src);
    }

    menu = memoize((): ItemType[] => {
        return this.getMenu(this.src);
    })

    protected getMenu(catalog: Catalog): ItemType[] {
        const result: ItemType[] = [];
        for (const key in catalog) {
            if (key === "id") continue;
            let children: ItemType[] = [];
            if (!Array.isArray(catalog[key]) && isObject(catalog[key])) {
                children = this.getMenu(catalog[key] as Catalog);
                result.push(this.getMenuItem(key, children, catalog[key].id));
            }
        }
        return result;
    }

    protected getMenuItem(item: string, children: ItemType[], id?: string): ItemType {
        return {
            label: item,
            key: id || item,
            children: isEmpty(children) ? undefined : children,
        }
    }

    getCharacteristics = memoize((path: string[]): Record<string, []> | undefined => {
        const leaf: string = path[path.length - 1];
        if (this.isExistingLeaf(leaf) && this.isLastCategoryLeaf(leaf)) {
            const characteristics = get(this.src, path.join(".") as string) || this.getCharacteristicsById(leaf);
            return omit(characteristics, "id");
        }
        return undefined;
    });

    isExistingLeaf = memoize((leaf: string) => {
        return this.pathToLeaf(leaf);
    });

    protected isLastCategoryLeaf = memoize((leaf: string): boolean => {
        const pathToLeaf: string = this.pathToLeaf(leaf) as string;
        const object = get(this.src, pathToLeaf);
        return Object.keys(object).every((key: string) => key === "id" || Array.isArray(object[key]));
    })

    getLastCategoryChildren = memoize((category: string): string[] => {
        if (!this.isExistingLeaf(category)) {
            return [];
        }
        if (this.isLastCategoryLeaf(category)) {
            return [category];
        }
        const path: string = this.pathToLeaf(category) as string;
        return this.getLastChildren(get(this.src, path));
    });

    getLastChildren(catalog: Catalog): string[] {
        const result: string[] = []
        for (const key in catalog) {
            if (this.isLastCategoryLeaf(key)) {
                result.push(catalog[key].id || key);
            } else {
                result.push(...this.getLastChildren(catalog[key]));
            }
        }

        return result;
    }

    get createdOn() {
        const timestamp: string = this.id.toString().substring(0, 8);
        const date = new Date(parseInt(timestamp, 16) * 1000);
        return date.toLocaleDateString("uk-UA");
    }


    getFileName() {
        return `(${this.createdOn}) catalogue_structure.json`;
    }

    getCategoryNameById(id: string, catalog: Catalog): string {
        let name: string = id;
        const getCategoryName = (id: string, catalog: Catalog, categoryName: string = "") => {
            for (let key in catalog) {
                if (catalog.id === id) {
                    name = categoryName;
                }
                if (!Array.isArray(catalog[key]) && isObject(catalog[key])) {
                    getCategoryName(id, catalog[key], key);
                }
            }
        };
        getCategoryName(id, catalog);
        return name;
    }

    getCharacteristicsById(id: string) {
        let characteristics: Catalog | null = null;

        const getCharacteristics = (catalog: Catalog) => {
            for (let key in catalog) {
                if (catalog[key].id === id) {
                    characteristics = catalog[key];
                } else if (!Array.isArray(catalog[key]) && isObject(catalog[key])) {
                    getCharacteristics(catalog[key]);
                }
            }
        };

        getCharacteristics(this.src);

        return characteristics;
    }

}