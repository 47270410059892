import {isNumber, isString} from "lodash-es";

export function formatDate(date?: Date | string | number) {
    if (!date) return "";
    if (isString(date) || isNumber(date)) {
        date = new Date(date);
    }
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
}

export function formatDateWithTime(date?: Date | string) {
    if (!date) return "";
    if (isString(date)) {
        date = new Date(date);
    }
    const hours = date.getHours().toString().padStart(1, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${formatDate(date)} ${hours}:${minutes}`;
}

export function getDaysDiff(date1: Date, date2: Date) {
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
}