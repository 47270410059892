import {PublicUserInfo} from "./PublicUserInfo";

export enum UserStatus {
    DISABLED = "DISABLED",
    DELETED = "DELETED"
}

export interface CurrentUserInfo extends PublicUserInfo {
    email: string;
    emailVerified: boolean;
    phoneNumber: string;
    disabled: boolean;
    customClaims: Record<string, any>;
    lastSignInTime: number;
    favoriteAds?: string[];
    status?: UserStatus;
    location?: string;
    deletedOn?: string;
}