import {FC, ReactNode, useEffect, useState} from "react";
import {Drawer} from "antd";
import {List} from "antd-mobile"
import {menu, STATIC_MENU_ITEMS, StaticMenuItemKeys} from "../headerMenu/MenuBuilder";
import {useCatalog} from "../../../commands/catalog/useCatalog";
import {SubMenuType} from "antd/lib/menu/hooks/useItems";
import {isEmpty} from "lodash-es";
import {Pages} from "../../../../routing/Pages";
import {createSearchParams, useNavigate} from "react-router-dom";
import {ReactComponent as Hamburger} from "./resources/Hamburger.svg";
import {ReactComponent as Close} from "./resources/Close.svg";
import {ReactComponent as ChevronRight} from "./resources/ChevronRight.svg";
import {ReactComponent as ChevronLeft} from "./resources/ChevronLeft.svg";
import {ReactComponent as ArrowLeft} from "./resources/ArrowLeft.svg";
import {Logo} from "../logo/Logo";
import {Footer} from "../../footer/Footer";
import "./styles/mobileMenu.scss";


export const MobileMenu: FC = () => {
    const [open, setOpen] = useState<boolean>(false);
    const navigate = useNavigate();
    const [catalog] = useCatalog();
    const [menuItems, setMenuItems] = useState<SubMenuType[][]>([]);
    const [selectedCategories, setSelectedCategories] = useState<SubMenuType[]>([]);

    const showDrawer = () => {
        setOpen(true);
    };

    const closeDrawer = () => {
        setOpen(false);
    };

    const goToPage = (item: SubMenuType) => {
        setOpen(false);
        const {key} = item;
        const isStatic: boolean = Object.values(StaticMenuItemKeys).includes(key as StaticMenuItemKeys);
        if (isStatic) {
            navigate({pathname: `/app/${key}`});
        } else {
            navigate({
                pathname: Pages.SEARCH,
                search: `?${createSearchParams({category: item.key})}`
            });
        }
    };

    const handleMenuItemClick = (item: SubMenuType) => {
        if (!isEmpty(item.children)) {
            setMenuItems([...menuItems, item.children] as SubMenuType[][]);
            setSelectedCategories([...selectedCategories, item]);
        } else {
            goToPage(item);
        }
    };

    const onBackClick = () => {
        const lastMenuItemIndex: number = menuItems.length - 1;
        const lastSelectedCategoryIndex: number = selectedCategories.length - 1;
        setMenuItems(menuItems.filter((_: unknown, index: number) => index !== lastMenuItemIndex));
        setSelectedCategories(selectedCategories.filter((_: unknown, index: number) => index !== lastSelectedCategoryIndex));
    };

    const getMenuItems = (): SubMenuType[] => {
        return (menuItems[menuItems.length - 1] ?? []) as SubMenuType[];
    };

    const getSelectedCategory = (): SubMenuType | undefined => {
        return selectedCategories[selectedCategories.length - 1];
    };

    const isBackButtonVisible: boolean = menuItems.length > 1;

    const title: ReactNode = (
        <div className="mobile-menu-drawer__title">
            <Logo/>
        </div>
    );

    useEffect(() => {
        setMenuItems([[...menu(catalog), ...STATIC_MENU_ITEMS] as SubMenuType[]]);
    }, [catalog]);

    return (
        <>
            <button onClick={showDrawer} className="mobile-menu-hamburger">
                <Hamburger/>
            </button>
            <Drawer
                title={title}
                placement="top"
                onClose={closeDrawer}
                open={open}
                width="100vw"
                height="100vh"
                className="mobile-menu-drawer"
                closeIcon={<Close/>}
            >
                {isBackButtonVisible && (
                    <button
                        onClick={onBackClick}
                        className="mobile-menu-drawer__back-button"
                    >
                        {getSelectedCategory()?.label ?? null}
                        <ChevronLeft/>
                    </button>
                )}
                <List>
                    {getMenuItems().map((item: SubMenuType) => {
                        const extra: ReactNode = isEmpty(item.children) ? null :
                            <ChevronRight/>;
                        return (
                            <List.Item
                                key={item.key}
                                extra={extra}
                                onClick={() => handleMenuItemClick(item)}
                                arrow={false}
                            >
                                {item.label}
                            </List.Item>
                        );
                    })}
                </List>
                {
                    isBackButtonVisible && (
                        <button className="mobile-menu-drawer__back-button--footer" onClick={onBackClick}>
                            <ArrowLeft/>Назад
                        </button>
                    )
                }
                {!isBackButtonVisible && (<Footer/>)}
            </Drawer>
        </>
    )
};