import {ComponentType, LazyExoticComponent, Suspense} from "react";
import "./styles/lazyPage.scss";
import {Spin} from "antd";

interface Props {
    component: LazyExoticComponent<ComponentType>;
}

export function LazyPage(props: Props) {
    const {component: Component} = props;
    return (
        <Suspense fallback={
            <div className="lazy-page-spin">
                <Spin size="large" spinning={true}/>
            </div>}>
            <Component/>
        </Suspense>
    );
}
