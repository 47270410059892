import FetchCommand from "../../fetch/FetchCommand";
import {api} from "../../fetch/api";
import {PublicUserInfo} from "../user/types/PublicUserInfo";

export default class GetShopsCommand extends FetchCommand<PublicUserInfo[], void> {
    static shops: PublicUserInfo[] | null = null;
    async execute() {
        if (GetShopsCommand.shops === null) {
            GetShopsCommand.shops = await api<PublicUserInfo[]>("/api/shops");
        }

        return GetShopsCommand.shops;
    }
}