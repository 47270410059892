import {FC} from "react";
import {FooterMenu} from "./footerMenu/FooterMenu";
import {Copyright} from "./copyright/Copyright";
import {ContactUsButton} from "./contactUsButton/ContactUsButton";
import {useCurrentUser} from "../../commands/user/useCurrentUser";
import {useIsMobile} from "../../hooks/useIsMobile";
import "./styles/footer.scss"

export const Footer: FC = () => {
    const [currentUser] = useCurrentUser();
    const isMobile = useIsMobile();

    if (currentUser?.isAdmin || currentUser?.isModerator) {
        return (
            <footer className="app-footer">
                <div className="app-footer__not-user">
                    <Copyright/>
                </div>
            </footer>
        );
    }

    return (
        <footer className="app-footer">
            {!isMobile && (<FooterMenu/>)}
            <div className="app-footer__bottom">
                <div className="app-footer__left">
                    <Copyright/>
                </div>
                <div className="app-footer__right">
                    <ContactUsButton/>
                </div>
            </div>
        </footer>
    );
};