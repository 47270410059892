import {Dispatch, useEffect, useRef, useState} from "react";
import {GetCurrentUser} from "./GetCurrentUser";
import {CurrentUserInfoDecorator} from "./types/CurrentUserInfoDecorator";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../../firebase/config";
import {needToVerifyEmail} from "../../utils/UserUtils";

export function useCurrentUser(): [CurrentUserInfoDecorator | null, Dispatch<CurrentUserInfoDecorator | null>, boolean] {
    const [fireBaseUser, isFirebaseUserLoading] = useAuthState(auth);
    const [user, setUser] = useState<CurrentUserInfoDecorator | null>(GetCurrentUser.user);
    const cancelRequest = useRef<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(isFirebaseUserLoading || GetCurrentUser.promise !== null);

    useEffect(() => {
        GetCurrentUser.subscribe(setUser);
        return () => {
            GetCurrentUser.unsubscribe(setUser);
        }
    }, []);

    useEffect(() => {
        if (fireBaseUser && !needToVerifyEmail(fireBaseUser)) {
            cancelRequest.current = false
            const getUser = async () => {
                if (cancelRequest.current) return;
                setIsLoading(true);
                await new GetCurrentUser().execute(fireBaseUser).finally(() => {
                    setIsLoading(false);
                });
            }
            getUser().catch(() => {
                if (cancelRequest.current) return;
            });
        } else {
            setIsLoading(isFirebaseUserLoading);
        }

        return () => {
            cancelRequest.current = true;
        }
    }, [fireBaseUser, isFirebaseUserLoading]);

    const setUpdatedUser = (user: CurrentUserInfoDecorator | null) => {
        GetCurrentUser.user = user;
    }

    return [user, setUpdatedUser, isLoading];
}