import {Component, lazy, ReactNode} from "react";
import {LazyPage} from "../../../content/lazyPage/LazyPage";

const ErrorScreen = lazy(() => import("../errorScreen/ErrorScreen"));

interface State {
    hasError: boolean;
}

interface Props {
    children: ReactNode;
}

export default class ErrorBoundary extends Component<Props, State> {
    state: State = {
        hasError: false
    }

    componentDidCatch() {
        this.setState({hasError: true});
    }

    render() {
        if (this.state.hasError) {
            return (<LazyPage component={ErrorScreen}/>);
        }
        return this.props.children;
    }
}