import {useScreenWidth} from "./useScreenWidth";
import {useEffect, useState} from "react";
import {isMobile} from "../components/screen/Screen";

export function useIsMobile() {
    const width: number = useScreenWidth();
    const [isMobileDevice, setIsMobileDevice] = useState(false);

    useEffect(() => {
        setIsMobileDevice(isMobile(width));
    }, [width]);

    return isMobileDevice;
}