import * as React from "react";
import {FC, useEffect, useState} from "react";
import AdvertisementDecorator from "../../types/decorators/AdvertisementDecorator";
import {ProductTile} from "../../common/components/productTile/ProductTile";
import {Spin} from "antd";
import FindTopCommand from "../../common/commands/search/FindTopCommand";
import {PartnersCarousel} from "../../common/components/partnersCarousel/PartnersCarousel";
import {useShops} from "../../common/hooks/useShops";
import {PublicUserInfo} from "../../common/commands/user/types/PublicUserInfo";
import "./style/landing.scss";

export const Landing: FC = () => {
    const [vipAds, setVIPAds] = useState<AdvertisementDecorator[]>([]);
    const [shops, loaded] = useShops();
    const shopIds: string[] = shops.map((item: PublicUserInfo) => item.id ?? "");
    const [vipAdsLoading, setVipAdsLoading] = useState<boolean>(true);


    useEffect(() => {
        if (loaded) {
            const userTags: Record<string, string[]>[] = [
                {shop: ["", ...shopIds]},
            ]
            setVipAdsLoading(true);
            new FindTopCommand().execute({tags: userTags, limit: 20})
                .then(({items}) => {
                    setVIPAds(items);
                })
                .finally(() => {
                    setVipAdsLoading(false);
                });
        }
    }, [loaded, shops]);

    return (
        <div className="landing">
            {!vipAdsLoading
                ? (
                    <div className="landing__ads">
                        {vipAds.map((advertisement: AdvertisementDecorator) => (

                            <ProductTile className="landing__product-tile" advertisement={advertisement}
                                         key={advertisement.id}/>
                        ))}
                    </div>

                )
                : (
                    <Spin spinning={true} className="landing__spin" size="large"/>
                )
            }
            <PartnersCarousel/>
        </div>
    );
}