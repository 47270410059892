import {DefaultOptionType} from "rc-select/lib/Select";

export const getCharacteristicOptions = (characteristics: Record<string, []> | undefined, key: string): DefaultOptionType[] => {
    if (characteristics) {
        return (characteristics[key] || []).reduce((result: DefaultOptionType[], value: string) => {
            if (value.toLowerCase() !== "інше")
                result.push({
                    value,
                    label: value
                });
            return result;
        }, [])
    }
    return [];
}

export const isCharacteristicOptional = (characteristics: Record<string, []> | undefined, key: string) => {
    if (characteristics) {
        return (characteristics[key] || []).some((item: string) => item.toLowerCase() === "інше");
    }
    return true;
}