import {useEffect, useState} from "react";
import GetShopsCommand from "../commands/shop/GetShopsCommand";
import {PublicUserInfo} from "../commands/user/types/PublicUserInfo";

export function useShops(): [PublicUserInfo[], boolean] {
    const [shops, setShops] = useState<PublicUserInfo[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        new GetShopsCommand().execute().then(setShops).catch(() => setShops([])).finally(() => setLoaded(true));
    }, []);

    return [shops, loaded];
}