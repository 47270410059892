const THUMBNAIL_SIZE: string = "300x300";

export function getThumbnailPath(path: string, dimensions: string = THUMBNAIL_SIZE): string {
    const pathParts: string[] = path.split('/');
    const filename: string = pathParts.pop() || "";  // Get filename from the path
    const directory: string = pathParts.join('/');
    const name: string = filename.split('.').slice(0, -1).join('.');  // Get name without extension

    const newDirectory: string = `${directory}/thumbnails`;
    const newFilename: string = `${name}_${dimensions}.webp`;

    return `${newDirectory}/${newFilename}`;
}